import React from "react";
import { dotSpinner } from "ldrs";

dotSpinner.register();

const Loader = () => {
	return (
		<div
			className="d-flex justify-content-center align-items-center"
			style={{ height: "100vh" }}
		>
			<l-dot-spinner
				size="36"
				speed="0.9"
				color="#4096ff"
			></l-dot-spinner>{" "}
		</div>
	);
};

export default Loader;
