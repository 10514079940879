import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.scss";
import Cookies from "js-cookie";
import { decodeToken } from "react-jwt";
import dayjs from "dayjs";
import fr_FR from "antd/locale/fr_FR";
import "dayjs/locale/fr";
import { ConfigProvider } from "antd";
import { lazy, Suspense } from "react";
import Loader from "./components/Loader";
const Home = lazy(() => import("./pages/Home"));
const NotFound = lazy(() => import("./pages/NotFound"));
const Login = lazy(() => import("./pages/Login"));
const Register = lazy(() => import("./pages/Register"));
const Users = lazy(() => import("./pages/admin/Users"));
const Inscriptions = lazy(() => import("./pages/admin/Inscriptions"));
const Session = lazy(() => import("./pages/admin/Session"));
const InscriptionStatus = lazy(() => import("./pages/admin/InscriptionStatus"));
const PaiementStatus = lazy(() => import("./pages/admin/PaiementStatus"));
const TypePaiement = lazy(() => import("./pages/admin/TypePaiement"));
const Categories = lazy(() => import("./pages/admin/Categories"));
const EtatInscription = lazy(() => import("./pages/admin/EtatInscription"));
const Emails = lazy(() => import("./pages/admin/Emails"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const Absence = lazy(() => import("./pages/coach/Absence"));
const Coach = lazy(() => import("./pages/admin/Coach"));

dayjs.locale("fr");

function App() {
	const token = Cookies.get("access_token");
	const decodedToken = token && decodeToken(token);
	function requireAuth(redirectTo) {
		return Cookies.get("access_token") ? (
			redirectTo
		) : (
			<Navigate to={"/login"} />
		);
	}

	function requireAuthAndCoach(redirectTo) {
		return (Cookies.get("access_token") &&
			decodedToken?.role === "ROLE_COACH") ||
			decodedToken?.role === "ROLE_ADMIN" ? (
			redirectTo
		) : (
			<Navigate to={"/login"} />
		);
	}

	function requireAuthAndAdmin(redirectTo) {
		return Cookies.get("access_token") &&
			decodedToken?.role === "ROLE_ADMIN" ? (
			redirectTo
		) : (
			<Navigate to={"/login"} />
		);
	}

	return (
		<div className="app">
			<Suspense fallback={<Loader />}>
				<ConfigProvider locale={fr_FR}>
					<BrowserRouter>
						<Routes>
							<Route path="/">
								<Route index element={requireAuth(<Home />)} />
								<Route
									path="/absence"
									element={requireAuthAndCoach(<Absence />)}
								/>
								<Route
									path="/users"
									element={requireAuthAndAdmin(<Users />)}
								/>
								<Route
									path="/coach"
									element={requireAuthAndAdmin(<Coach />)}
								/>
								<Route
									path="/inscriptions"
									element={requireAuthAndAdmin(
										<Inscriptions />
									)}
								/>
								<Route
									path="/sessions"
									element={requireAuthAndAdmin(<Session />)}
								/>
								<Route
									path="/inscription/status"
									element={requireAuthAndAdmin(
										<InscriptionStatus />
									)}
								/>
								<Route
									path="/paiement/status"
									element={requireAuthAndAdmin(
										<PaiementStatus />
									)}
								/>
								<Route
									path="/type/paiement"
									element={requireAuthAndAdmin(
										<TypePaiement />
									)}
								/>
								<Route
									path="/categories"
									element={requireAuthAndAdmin(
										<Categories />
									)}
								/>
								<Route
									path="/etat/inscription"
									element={requireAuthAndAdmin(
										<EtatInscription />
									)}
								/>
								<Route
									path="/emails"
									element={requireAuthAndAdmin(<Emails />)}
								/>
								<Route
									path="/login"
									element={
										Cookies.get("access_token") ? (
											<Navigate to="/" />
										) : (
											<Login />
										)
									}
								/>
								<Route
									path="/register"
									element={
										Cookies.get("access_token") ? (
											<Navigate to="/" />
										) : (
											<Register />
										)
									}
								/>
								<Route
									path="/forgot-password"
									element={<ForgotPassword />}
								/>
								<Route
									path="/reset-password/:id/:token"
									element={<ResetPassword />}
								/>
								<Route path="*" element={<NotFound />} />
							</Route>
						</Routes>
					</BrowserRouter>
				</ConfigProvider>
			</Suspense>
		</div>
	);
}

export default App;
